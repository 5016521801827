//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	name: 'HomepageAnimation',

	data () {
		return {
			products: [
				{ src: 'apple-3', class: 'fruit', style: 'bottom: 40rem; left: 26rem;' },
				{ src: 'peach-2', class: 'fruit', style: 'bottom: 34rem; left: 16rem;' },
				{ src: 'apple-4', class: 'fruit', style: 'bottom: 50rem; right: 16rem;' },
				{ src: 'strawberry-2', class: 'fruit', style: 'bottom: 30rem; left: 50%; transform: translateX(-40%)' },
				{ src: 'strawberry-3', class: 'fruit', style: 'bottom: 42rem; right: 4rem;' },
				{ src: 'apple-bottle', class: 'bottle', style: 'top: 16rem; left: 50%; transform: translateX(34%)' },
				{ src: 'peach-bottle', class: 'bottle', style: 'top: 22rem; left: 50%; transform: translateX(-100%)' },
				{ src: 'strawberry-bottle', class: 'bottle', style: 'top: 0; left: 50%; transform: translateX(-29%)' },
				{ src: 'strawberry-1', class: 'fruit', style: 'bottom: 15rem; left: 50%; transform: translateX(-45%)' },
				{ src: 'peach-1', class: 'fruit', style: 'bottom: 20rem; left: 0;' },
				{ src: 'apple-2', class: 'fruit', style: 'bottom: 16rem; right: 0;' },
				{ src: 'apple-1', class: 'fruit', style: 'bottom: 0; left: 50%; transform: translateX(-42%)' },
			],
		};
	},
};

