//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import anime from 'animejs';
import { mapState } from 'vuex';

export default {
	name: 'HomePage',

	data () {
		return {
			animationBoy: null,
			animationBoyPlayed: false,
		};
	},

	async fetch () {
		await this.$store.dispatch('products/getProducts');
		try {
			const response = await this.$axios.get('/blogs/staticHome');
			await this.$store.dispatch('blogs/fetchBlogsHome', response.data.payload.items);
		}
		catch (e) {
			this.$nuxt.error({
				statusCode: 404,
				message: 'Unable to fetch homepage text at this time. Please try again.',
			});
		}
	},

	head () {
		return {
			title: this.$config.webTitle[this.$i18n.locale],
			meta: [
				{
					name: 'description',
					content: this.$config.webDescription[this.$i18n.locale],
				},
				{
					property: 'og:title',
					content: this.$config.webTitle[this.$i18n.locale],
				},
				{
					property: 'og:description',
					content: this.$config.webDescription[this.$i18n.locale],
				},
			],
		};
	},

	computed: {
		...mapState({
			home: (state) => state.blogs.home,
		}),

		products () {
			return this.$store.getters['products/products'];
		},
	},

	mounted () {
		if (window.screen.width >= 1025) {
			this.setAnimationIntro();
			this.setAnimationBoy();
			document.addEventListener('scroll', this.onScroll);
		}

		if (this.$router.currentRoute.query.slide || this.$router.currentRoute.fullPath === '/#prichute') {
			setTimeout(() => {
				document.getElementById('prichute').scrollIntoView({ behavior: 'smooth' });
			}, 200);
		}
	},

	methods: {
		onScroll () {
			let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			if (window.screen.width >= 1025 && scrollPosition > 900 && scrollPosition < 1400 && !this.animationBoyPlayed) {
				this.animationBoyPlayed = true;
				this.animationBoy.play();
			}
		},

		setAnimationBoy () {
			this.animationBoy = anime({
				targets: '.section-primary-image-boy',
				translateX: -450,
				translateY: 50,
				direction: 'reverse',
				delay: function (el, i) { return i * 100; },
				elasticity: 200,
				duration: 1500,
				easing: 'easeInOutSine',
				autoplay: false,
			});
		},

		setAnimationIntro () {
			anime({
				targets: '.homepage-header-products-content .fruit',
				translateX: anime.stagger(70, { grid: [ 5, 4 ], from: 'center', axis: 'x' }),
				translateY: anime.stagger(70, { grid: [ 5, 4 ], from: 'center', axis: 'y' }),
				delay: anime.stagger(300, { grid: [ 5, 4 ], from: 'center' }),
				easing: 'easeInOutQuad',
				direction: 'reverse',
			});
			anime({
				targets: '.homepage-header-products-content .bottle',
				translateY: 100,
				easing: 'easeInOutQuad',
				direction: 'reverse',
				delay: function (el, i, l) {
					return i * 500;
				},
				endDelay: function (el, i, l) {
					return (l - i) * 500;
				},
			});
			anime({
				targets: '.homepage-header-products-content .fireworks',
				scale: [ 0, 1 ],
				easing: 'easeInOutQuad',
				delay: 1800,
			});
		},
	},
};
