//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		products: {
			type: Array,
			required: true,
		},
	},

	data () {
		return {
			swiperOption: {
				navigation: {
					nextEl: '.product__nav--next',
					prevEl: '.product__nav--prev',
				},
				resistanceRatio: 0.5,
				loop: true,
				effect: 'fade',
				speed: 400,
				autoHeight: true,
				threshold: 5,
			},
		};
	},

	computed: {
		currentProductIndex () {
			return this.products.findIndex(product => product.link === this.$route.query.slide);
		},
	},

	mounted () {
		if (!this.$route.query.slide) {
			// this.$router.push({ query: { slide: this.products[0].link } }); // zobrazit slide kdyz neni query "slide"
			return;
		}

		if (this.currentProductIndex === -1) {
			// this.$router.replace({ query: { slide: this.products[0].link } }).catch(() => true); // zobrazit slide kdyz neexistuje slide
			return;
		}

		this.mySwiper.slideToLoop(this.currentProductIndex, 0, false);
	},

	methods: {
		changeRoute () {
			if (this.products[this.mySwiper.realIndex].link === this.$route.query.slide) {
				return;
			}
			this.$router.replace({ query: { slide: this.products[this.mySwiper.realIndex].link } }).catch(() => true);
		},
	},
};
